var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"c6a3064b23ce4b57aa4ccc05f54e5330ae0170c0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
    dsn:
        SENTRY_DSN ||
        'https://845761b483d04ff385beb8747880c0e7@o367719.ingest.sentry.io/5917176',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    enabled: process.env.NODE_ENV !== 'development',
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    // Ignore errors caused by safe links etc.
    // See https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-954602174
    ignoreErrors: [
        'Non-Error promise rejection captured',
        'ceCurrentVideo.currentTime',
        'This Suspense boundary received an update before it finished hydrating',
    ],
})
